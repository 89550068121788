// https://github.com/icons-pack/react-simple-icons/issues/208
// @ts-expect-error
import { default as SiGithub } from "@icons-pack/react-simple-icons/icons/SiGithub.mjs";
// @ts-expect-error
import { default as SiGoogle } from "@icons-pack/react-simple-icons/icons/SiGoogle.mjs";
import { Form } from "@remix-run/react";
import { ReactNode } from "react";
import { Button, ButtonProps } from "~/components/ui/button";
import { cn } from "~/lib/utils/tailwind.util";

export type SocialLoginProps = { className?: string; enableGithub?: boolean };

export function SocialLogin({
  className,
  enableGithub = true,
}: SocialLoginProps) {
  return (
    <div className={cn("flex justify-between gap-2", className)}>
      {enableGithub && (
        // TODO check if forms should use GET https://github.com/sergiodxa/remix-auth/blob/main/docs/avoid-redirects.md
        <Form action="/auth/github" method="POST" className="w-full">
          <SocialLoginButton icon={<SiGithub size={16} />} label={"Github"} />
        </Form>
      )}
      <Form action="/auth/google" method="POST" className="w-full">
        <SocialLoginButton icon={<SiGoogle size={16} />} label={"Google"} />
      </Form>
    </div>
  );
}

type SocialLoginButtonProps = {
  icon: ReactNode;
  label: string;
} & ButtonProps;

function SocialLoginButton({
  icon,
  label,
  ...buttonProps
}: SocialLoginButtonProps) {
  return (
    <Button className="w-full gap-3" size={"sm"} {...buttonProps}>
      {icon}
      <span>{label}</span>
    </Button>
  );
}
